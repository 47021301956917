<template>
  <v-dialog v-model="dialog" max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        dark
        outlined
        small
        class="rounded-xl"
        style="color: #4f4f4f"
      >
        View Details
      </v-btn>
    </template>
    <v-form>
      <v-row v-if="type" class="pa-4" style="background: white; color: #00072b">
        <v-col cols="12">
          <div class="fn20-weight7" style="color: #1a347f">
            Redemption Detail
          </div>
        </v-col>
        <v-col cols="6" class="pl-8 pb-0">
          <div class="fn14-weight7">Date & Time</div>
        </v-col>
        <v-col cols="6" class="pb-0">
          <div class="fn14-weight7">Reward Name</div>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-divider style="border: 1px solid #dfe2e9" />
        </v-col>
        <v-col cols="6" class="pl-8 pb-0">
          <div class="fn14">{{ convertDate(items.date) }}</div>
        </v-col>
        <v-col cols="6" class="pb-0">
          <div class="fn14">{{ items.reward.reward_name }}</div>
        </v-col>
        <v-col cols="12" class="">
          <v-divider style="border: 1px solid #dfe2e9" />
        </v-col>
        <v-col cols="6" class="pl-8">
          <div class="fn14-weight7">Customer Name</div>
        </v-col>
        <v-col cols="6" class="">
          <div class="fn14-weight7">Point</div>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-divider style="border: 1px solid #dfe2e9" />
        </v-col>
        <v-col cols="6" class="pl-8 pb-0">
          <div class="fn14">{{ items.customer }}</div>
        </v-col>
        <v-col cols="6" class="pb-0">
          <div class="fn14">{{ items.point }}</div>
        </v-col>
        <v-col cols="12" class="">
          <v-divider style="border: 1px solid #dfe2e9" />
        </v-col>
        <v-col cols="12" class="pl-8">
          <div class="fn14-weight7">Delivery Address</div>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-divider style="border: 1px solid #dfe2e9" />
        </v-col>
        <v-col cols="12" class="pl-8 pb-0">
          <div class="fn14">{{ items.delevery_address }}</div>
        </v-col>
        <v-col cols="12" class="">
          <v-divider style="border: 1px solid #dfe2e9" />
        </v-col>
        <v-col cols="12" class="pl-8">
          <div class="fn14-weight7">Tracking Number</div>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-divider style="border: 1px solid #dfe2e9" />
        </v-col>
        <v-col cols="12" class="pl-8 pb-0">
          <v-text-field
            label="Tracking Number"
            outlined
            dense
            v-model="items.tracking_number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="">
          <v-divider style="border: 1px solid #dfe2e9" />
        </v-col>
        <v-col cols="12" class="pl-8">
          <div class="fn14-weight7">Status</div>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-divider style="border: 1px solid #dfe2e9" />
        </v-col>
        <v-col cols="12" class="pl-8 pb-0">
          <div class="fn14">{{ items.status }}</div>
        </v-col>
        <v-col cols="12" class="">
          <v-divider style="border: 1px solid #dfe2e9" />
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn
            dark
            class="rounded-xl ml-2"
            width="171"
            height="39"
            outlined
            style="color: red"
            @click="dialog = false"
          >
            cancel
          </v-btn>

          <v-btn
            dark
            class="rounded-xl ml-2"
            width="171"
            height="39"
            style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
            @click="submit()"
          >
            SAVE
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else class="pa-4" style="background: white">
        <v-col cols="6">
          <div class="fn20-weight7" style="color: #1a347f">User Detail</div>
        </v-col>

        <v-row class="px-4" style="color: #1a347f">
          <v-col cols="6">
            <div class="fn16">First Name</div>
          </v-col>
          <v-col cols="6">
            <div class="fn16">Last Name</div>
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-text-field
              v-model="items.firstname"
              dense
              hide-details
              outlined
              readonly
            />
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-text-field
              v-model="items.lastname"
              dense
              hide-details
              outlined
              readonly
            />
          </v-col>
          <v-col cols="6" class="pt-0">
            <div class="fn16">Type</div>
          </v-col>
          <v-col cols="6" class="pt-0">
            <div class="fn16">Medical License No.</div>
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-text-field
              v-model="items.type"
              dense
              hide-details
              outlined
              readonly
            />
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-text-field
              v-model="items.medical_license_no"
              dense
              hide-details
              outlined
              readonly
            />
          </v-col>
          <v-col cols="6" class="pt-0">
            <div class="fn16">Registered Date:</div>
          </v-col>
          <v-col cols="6" class="pt-0">
            <div class="fn16">Status</div>
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-text-field
              v-model="items.createdAt"
              dense
              hide-details
              outlined
              readonly
            >
            </v-text-field>
            <!-- <v-row align="center" justify="center">
              <v-col cols="6"> -->
            <!-- <v-menu
              v-model="menufrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              disabled
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="items.createdAt"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="items.createdAt"
                @input="menufrom = false"
              ></v-date-picker>
            </v-menu> -->
            <!-- </v-col>
            </v-row> -->
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-text-field
              v-model="items.status"
              dense
              hide-details
              outlined
              readonly
            />
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-divider style="border: 1px solid #9bbef2" />
          </v-col>
        </v-row>

        <v-row class="px-4" style="color: #1a347f">
          <v-col cols="12">
            <div class="fn20-weight7">BI Points</div>
          </v-col>
          <v-col cols="6" class="pt-0">
            <div class="fn16">Available</div>
          </v-col>
          <v-col cols="6" class="pt-0">
            <div class="fn16">Expired on 31 Dec 2021</div>
          </v-col>
          <v-col cols="5" class="pt-0 ml-8">
            <div class="fn14" style="color: #9f9f9f">
              {{ items.bi_current_point }} points
            </div>
          </v-col>
          <v-col cols="5" class="pt-0 ml-16">
            <div class="fn14" style="color: #9f9f9f">120 points</div>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-divider style="border: 1px solid #9bbef2" />
          </v-col>
        </v-row>

        <v-row class="px-4" style="color: #1a347f">
          <v-col cols="12">
            <div class="fn20-weight7">Redeem History</div>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-data-table
              :headers="headers"
              :items="itemstable"
              :search="search"
              :items-per-page="5"
              class="elevation-0"
            >
              <template v-slot:header.date="{ header }">
                <span class="fn14-weight7 black--text">{{ header.text }}</span>
              </template>
              <template v-slot:header.name="{ header }">
                <span class="fn14-weight7 black--text">{{ header.text }}</span>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-divider style="border: 1px solid #9bbef2" />
          </v-col>
        </v-row>

        <v-row class="px-4" style="color: #1a347f">
          <v-col cols="6">
            <div class="fn20-weight7">CE Credits History</div>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              dark
              class="rounded-xl ml-2"
              width="171"
              height="39"
              style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
            >
              Add Credit
            </v-btn>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              class="mb-4"
              v-model="search_2"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-data-table
              :headers="headerTwo"
              :items="itemstableTwo"
              :search="search_2"
              :items-per-page="5"
              class="elevation-0"
            >
              <template v-slot:header.date="{ header }">
                <span class="fn14-weight7 black--text">{{ header.text }}</span>
              </template>
              <template v-slot:header.name="{ header }">
                <span class="fn14-weight7 black--text">{{ header.text }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-row>
    </v-form>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
  },
  props: {
    items: Object,
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menufrom: false,
      dialog: false,
      start: 1,
      search: "",
      headers: [
        { text: "Date & Time", value: "date" },
        { text: "Reward Name", value: "name" },
      ],
      itemstable: [
        // {
        //   date: "2021 Dec 23",
        //   name: "Teddy Bear Giftset",
        // },
        // {
        //   date: "2021 Dec 23",
        //   name: "Teddy Bear Giftset",
        // },
      ],
      search_2: "",
      headerTwo: [
        { text: "Date & Time", value: "date" },
        { text: "Credits", value: "credit" },
      ],
      itemstableTwo: [
        // {
        //   date: "2021 Dec 23",
        //   credit: 50,
        // },
        // {
        //   date: "2021 Dec 23",
        //   credit: 10,
        // },
      ],

      menufrom: false,
      product: "",
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  watch: {
    "this.dialog"(val) {
      console.log(val);
      if (val) {
        this.getAllUserOne();
      }
    },
    "items.createdAt"(val) {
      if (val) {
        return moment(val).format("MMM Do YYYY h:mm");
      }
    },
  },
  created() {
    this.getAllUserOne();
  },

  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  methods: {
    convertDate(val) {
      console.log("val", val);
      return moment(val).format("MMM Do YYYY h:mm");
    },
    async getAllUserOne() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/${this.items.id}`,
        auth
      );
      console.log("usersusersusersusersusersusersusers", response.data);
      // this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.picture_1.splice(1, 1);
    },
    cancel() {
      this.$router.push("Managebanners");
    },
    async submit() {
      console.log("this.items.id", this.items.id);
      // if (this.$refs.createForm.validate(true)) {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const data = {
        // id:this.items.id,
        tracking_number: this.items.tracking_number,
      };

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/redemption/${this.items.id}`,
        data,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `อัพเดตสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.dialog = false;
      } else {
        this.$swal.fire({
          icon: "error",
          text: `อัพเดตไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  // },
};
</script>

<style scoped>
.fieldColor {
  border-color: #1a347f;
}
</style>
