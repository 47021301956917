<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4 mb-4"
      ><download-excel
        class="btn btn-default"
        :data="list1"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="ผู้ใช้งานทั้งหมด.xls"
        :header="'ข้อมูลผู้ใช้งานทั้งหมด'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        ข้อมูลผู้ใช้งานทั้งหมด
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  // :formDate="datefrom"
  // :toDate="dateto"
  props: ["data"],
  data() {
    return {
      json_fields: {
        // รหัสบัตรเงินสด: "voucherCode",
        ชื่อ: "firstName",
        นามสกุล: "lastName",
        หมายเลขโทรศัพท์: "telNumber",
        Line: "line",
        ประเภท: "role",
        โปรไฟล์สาธารณะ: "isPublic",
        "Healthexpress PRO": "isPro",
        //  { text: "ชื่อ", value: "name", align: "center" },
        // { text: "หมายเลขโทรศัพท์", value: "telNumber", align: "center" },
        // { text: "Line", value: "line", align: "center" },
        // { text: "Email", value: "email", align: "center" },
        // { text: "ประเภท", value: "role", align: "center" },
        // { text: "โปรไฟล์สาธารณะ", value: "isPublic", align: "center" },
        // { text: "Healthexpress PRO", value: "isPro", align: "center" },
        // { text: "ตัวเลือก", value: "action", align: "center" },
      },

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    this.getAllAdmins();
  },
  methods: {
    async getAllAdmins() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/`,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("users", response.data);
      this.list1 = response.data.data;
      for (let i in this.list1) {
        this.list1[i].count = parseInt(i) + 1;
      }
    },
  },
};
</script>
