var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"fn24-weight7"},[_vm._v("Redemption")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-badge',{attrs:{"overlap":"","dot":"","color":"red"}},[_c('v-icon',{attrs:{"color":"#1a347f","large":""}},[_vm._v(" mdi-bell-outline ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4 pb-0",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"mr-4 pb-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","items":['', 'ท้ังหมด'],"label":"ค้นหา status"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"rounded-xl ml-2",staticStyle:{"background":"linear-gradient(268.1deg, #07204b, #1a347f)"},attrs:{"dark":"","width":"171","height":"39"}},[_vm._v(" Export ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"header.date",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.reward.reward_name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.user.firstname",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.point",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.delevery_address",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.tracking_number",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.action",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.delevery_address",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.delevery_address)+" ")])]}},{key:"item.tracking_number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.tracking_number)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"dense":"","hide-details":"","outlined":"","items":['Waiting', 'Delivered', 'Cancel']},on:{"change":function($event){return _vm.updateStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('span',[_vm._v(_vm._s(_vm.convertDate(item.date)))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('viewUser',{attrs:{"dialog":_vm.dialog,"items":item,"type":"redemption"}})],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }