<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="6">
        <div class="fn24-weight7">Redemption</div>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-badge overlap dot color="red">
          <v-icon color="#1a347f" large> mdi-bell-outline </v-icon>
        </v-badge>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4 pb-0">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field> </v-col
          ><v-col cols="4" class="mr-4 pb-0">
            <v-autocomplete
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              :items="['', 'ท้ังหมด']"
              label="ค้นหา status"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="6" class="text-right">
            <!-- <v-btn
              dark
              outlined
              class="rounded-xl"
              width="171"
              height="39"
              style="color: #1a347f"
            >
              Export
            </v-btn> -->
            <v-btn
              dark
              class="rounded-xl ml-2"
              width="171"
              height="39"
              style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
            >
              Export
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:header.date="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.reward.reward_name="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.user.firstname="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.point="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.delevery_address="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.tracking_number="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.status="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:[`item.delevery_address`]="{ item }">
              <span
                class="d-inline-block text-truncate"
                style="max-width: 200px"
              >
                {{ item.delevery_address }}
              </span>
            </template>
            <template v-slot:[`item.tracking_number`]="{ item }">
              <span
                class="d-inline-block text-truncate"
                style="max-width: 200px"
              >
                {{ item.tracking_number }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-autocomplete
                dense
                hide-details
                outlined
                :items="['Waiting', 'Delivered', 'Cancel']"
                v-model="item.status"
                @change="updateStatus(item)"
              ></v-autocomplete>
              <!-- <span
                :style="
                  item.status === 'Waiting'
                    ? 'color: yellow;'
                    : item.status === 'Delivered'
                    ? 'color: green;'
                    : 'color: red;'
                "
                >{{ item.status }}</span
              > -->
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <v-row justify="center">
                <span>{{ convertDate(item.date) }}</span>
              </v-row>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <viewUser :dialog="dialog" :items="item" type="redemption" />
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import ExportUser from "@/views/ExportExcel/ExportUser.vue";
import moment from "moment";
import { Decode, Encode } from "@/services";
import viewUser from "@/components/Shared/viewUser";
export default {
  components: {
    ExportUser,
    viewUser,
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Date & Time", value: "date", align: "center", width: 140 },
        {
          text: "Reward Name",
          value: "reward.reward_name",
          align: "center",
          width: 200,
        },
        {
          text: "Customer Name",
          value: "user.firstname",
          align: "center",
          width: 170,
        },
        { text: "Points", value: "point", align: "center", width: 100 },
        {
          text: "Delivery Address",
          value: "delevery_address",
          align: "center",
        },
        {
          text: "Tracking Number",
          value: "tracking_number",
          align: "center",
          width: 200,
        },
        { text: "Status", value: "status", align: "center", width: "180" },
        { text: "Actions", value: "action", align: "center" },
      ],
      items: [
        // {
        //   dateTime: "10/1/21 12:23",
        //   reward: "Teddy Bear",
        //   customer: "Jaidee Dekdee",
        //   point: 100,
        //   address: "103/4232 Jaransanit Watthapra Bangkokyai Bangkok 10600",
        //   status: "Waiting",
        // },
        // {
        //   dateTime: "10/1/21 12:23",
        //   reward: "Teddy Bear",
        //   customer: "Jaidee Dekdee",
        //   point: 100,
        //   address: "103/4232 Jaransanit Watthapra Bangkokyai Bangkok 10600",
        //   status: "Delivered",
        // },
        // {
        //   dateTime: "10/1/21 12:23",
        //   reward: "Teddy Bear",
        //   customer: "Jaidee Dekdee",
        //   point: 100,
        //   address: "103/4232 Jaransanit Watthapra Bangkokyai Bangkok 10600",
        //   status: "Delivered",
        // },
        // {
        //   dateTime: "10/1/21 12:23",
        //   reward: "Teddy Bear",
        //   customer: "Jaidee Dekdee",
        //   point: 100,
        //   address: "103/4232 Jaransanit Watthapra Bangkokyai Bangkok 10600",
        //   status: "Delivered",
        // },
        // {
        //   dateTime: "10/1/21 12:23",
        //   reward: "Teddy Bear",
        //   customer: "Jaidee Dekdee",
        //   point: 100,
        //   address: "103/4232 Jaransanit Watthapra Bangkokyai Bangkok 10600",
        //   status: "Delivered",
        // },
      ],
      search: "",
      count: 0,
      status: "",
    };
  },
  created() {
    this.getAllredemption();
  },
  methods: {
    convertDate(val) {
      return moment(val).format("MMM Do YYYY");
    },
    async getAllredemption() {
      const selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/redemption?usertype=${selectedType}`,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("redemption", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    // async getAllExperts() {
    //   var user = JSON.parse(
    //     Decode.decode(localStorage.getItem("userBiAdmin"))
    //   );
    //   const auth = {
    //     headers: {
    //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
    //     },
    //   };
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/users/?role=` + "EXPERT",
    //     auth
    //   );
    //   console.log("users", response.data);
    //   this.items = response.data.data;
    //   for (let i in this.items) {
    //     this.items[i].count = parseInt(i) + 1;
    //   }
    // },
    viewUser(val) {
      localStorage.setItem("userdata", Encode.encode(val));
      // console.log(val);
      this.$router.push("viewUser");
    },
    async updateStatus(val) {
      console.log(val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const data = {
        // reward_name: val.reward_name,
        status: val.status,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/redemption/${val.id}`,
        data,
        auth
      );
    },
    async DeleteUser(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        // cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiAdmin"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/users/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllUsers();
        }
      });
    },
    goToCreate() {
      this.$router.push("GenRefCode");
    },
    tab(val) {
      if (val.key == "EXPERT") {
        this.getAllExperts();
      }
      if (val.key == "USER") {
        this.getAllUsers();
      }
    },
  },
};
</script>
